import ApiService from "@/core/services/api.service";
// action types
export const FETCH_CHECKLIST = "fetchChecklist";
export const FETCH_CHECKLIST_BY_UUID = "fetchChecklistByUuid";
export const SAVE_CHECKLIST = "saveChecklist";
// mutation types
export const SET_CHECKLIST = "setChecklist";
export const SET_CHECKLIST_INFO = "setChecklistInfo";
export const SET_COMMON_INFO = "setCommonInfo";
export const PURGE_CHECKLIST = "purgeChecklist";
export const SET_CHECKLIST_LOADED = "setChecklistLoaded";
export const SET_QUESTION_INDEX = "setQuestionIndex";
export const SET_UUID = "setUuid";
export const SET_FINISHED_ID = "setFinishedId";

const state = {
  common_info: {},
  checklist_info: {},
  isChecklistLoaded: false,
  checklist: {},
  questionIndex: 0,
  uuid4: null,
  finishedId: null
};

function buildQuestionTray(questions, question) {
  var ret = question;
  if (question.answers.length > 0) {
    question.answers.forEach(answer => {
      //console.log(answer);
      var subqarr = questions.filter(quest => quest.parentId_id === answer.id);
      //console.log(subqarr);
      if (subqarr.length > 0) {
        answer.questions = [];
        subqarr.forEach(q => {
          answer.questions.push(buildQuestionTray(questions, q));
          //Object.assign(answer, {questions: buildQuestionTray(questions, q)});
        });
      }
    });
  }
  return ret;
}

function shuffle(array) {
  var currentIndex = array.length,
    randomIndex;

  // While there remain elements to shuffle...
  while (0 !== currentIndex) {
    // Pick a remaining element...
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex--;

    // And swap it with the current element.
    [array[currentIndex], array[randomIndex]] = [
      array[randomIndex],
      array[currentIndex]
    ];
  }

  return array;
}

const getters = {
  getCheckList(state) {
    return state.checklist;
  },
  getChecklistInfo(state) {
    return state.checklist_info;
  },
  getCommonInfo(state) {
    return state.common_info;
  },
  isChecklistLoaded(state) {
    return state.isChecklistLoaded;
  },
  getQuestionIndex(state) {
    return state.questionIndex;
  },
  getUuid(state) {
    return state.uuid4;
  },
  getFinishedId(state) {
    return state.finishedId;
  }
};

const actions = {
  [FETCH_CHECKLIST]({ commit }, payload) {
    // console.log(payload);
    return new Promise(resolve => {
      ApiService.post("api/v1/rp/", payload)
        .then(({ data }) => {
          // console.log(data);
          // console.log(data.questions);
          var checklist = {};
          // console.log(data.visibleType);
          if (data.visibleType) {
            var order = 1;
            checklist = {
              section_start: {
                name: "Общая информация",
                questions: []
              },
              sections: [],
              section_finish: {
                name: "Заключение специалиста"
              }
            };
            data.questions.forEach(question => {
              if (question.parentId_id === null) {
                question.parentId_id = 0;
              }
              question.answer = null;
              if (question.partition === "Общая информация") {
                checklist.section_start.questions.push(question);
              } else if (question.partition === "Заключение специалиста") {
                checklist.section_finish.questions.push(question);
              } else {
                if (
                  !checklist.sections.some(section => {
                    return section.name === question.partition;
                  })
                ) {
                  checklist.sections.push({
                    name: question.partition,
                    order: order++,
                    questions: []
                  });
                }
                if (question.parentId_id === 0) {
                  checklist.sections
                    .find(el => el.name === question.partition)
                    .questions.push(
                      buildQuestionTray(data.questions, question)
                    );
                }
              }
            });
          } else {
            var questions = [];
            var subquestions = [];
            var questions_start = [];
            var questions_finish = [];

            data.questions.forEach(question => {
              if (question.parentId_id === null) {
                question.parentId_id = 0;
              }
              question.answer = null;
              if (question.partition === "Общая информация") {
                if (
                  question.questName !== "Комментарий" &&
                  question.questName !==
                    "Любой дополнительный комментарий по инспекции"
                ) {
                  question.requared = true;
                }
                questions_start.push(question);
              } else if (question.partition === "Заключение специалиста") {
                question.requared = true;
                questions_finish.push(question);
              } else {
                if (question.parentId_id === 0) {
                  questions.push(question);
                } else {
                  subquestions.push(question);
                }
              }
            });

            checklist = {
              sections: {
                questions:
                  data.hasOwnProperty("randomize") && data.randomize
                    ? shuffle(questions).concat(
                        questions_start,
                        questions_finish
                      )
                    : questions.concat(questions_start, questions_finish)
              },
              /*section_start: {
                questions: questions_start
              },
              section_finish: {
                questions: questions_finish
              },*/
              section_sub: {
                questions: subquestions
              }
            };
            //checklist.section.questions.push(questions_start);
            //checklist.section.questions.push(questions_finish);
          }
          //  console.log(checklist);
          // console.log(data.hasOwnProperty("mainProc") ? data.mainProc : {});
          commit(SET_CHECKLIST, checklist);
          commit(SET_CHECKLIST_INFO, {
            id: data.id,
            processType: data.resolveProcess,
            processName: data.processName,
            processDescription: data.description,
            processAim: data.condition_type_id,
            processTime: 0,
            hours:
              data.time_before === null && data.time_after === null
                ? true
                : false,
            processDateStart: data.dateBegin,
            processDateFinish: data.dateEnd,
            processPeriod: null,
            payment: data.payment,
            price: data.price,
            timeStart: data.time_before,
            timeFinish: data.time_after,
            locations: [],
            roles: [],
            mainProc: data.hasOwnProperty("mainProc") ? data.mainProc : {},
            visibleType: data.visibleType,
            randomize: data.hasOwnProperty("randomize") ? data.randomize : false
          });
          commit(SET_CHECKLIST_LOADED, true);
          resolve(data);
        })
        .catch(response => {
          console.error(response);
        });
    });
  },

  [FETCH_CHECKLIST_BY_UUID]({ commit }, payload) {
    // console.log(commit);
    // console.log(payload);
    return new Promise(resolve => {
      ApiService.post("api/v1/rp/", payload)
        .then(({ data }) => {
          //console.log(data);
          // console.log(data.questions);
          var checklist = {};
          var addedq = {};
          //console.log(data.visibleType);
          if (data.visibleType) {
            var order = 1;
            checklist = {
              section_start: {
                name: "Общая информация",
                questions: [
                  {
                    questName: "Укажите email для получения заключения",
                    dataTypeId_id: 3,
                    requared: true,
                    answer: null,
                    answers: [],
                    dataFormat: null,
                    fileData_id: null,
                    id: 9999999999901,
                    inspectionId_id: data.id,
                    parentId_id: 0,
                    partition: "Общая информация"
                  },
                  {
                    questName: "Укажите имя",
                    dataTypeId_id: 3,
                    requared: false,
                    answer: null,
                    answers: [],
                    dataFormat: null,
                    fileData_id: null,
                    id: 9999999999902,
                    inspectionId_id: data.id,
                    parentId_id: 0,
                    partition: "Общая информация"
                  },
                  {
                    questName: "Укажите фамилию",
                    dataTypeId_id: 3,
                    requared: false,
                    answer: null,
                    answers: [],
                    dataFormat: null,
                    fileData_id: null,
                    id: 9999999999903,
                    inspectionId_id: data.id,
                    parentId_id: 0,
                    partition: "Общая информация"
                  }
                ]
              },
              sections: [],
              section_finish: {
                name: "Заключение специалиста",
                questions: []
              }
            };
            data.questions.forEach(question => {
              if (question.parentId_id === null) {
                question.parentId_id = 0;
              }
              question.answer = null;
              if (question.partition === "Общая информация") {
                if (
                  question.questName !== "Ф.И.О. проводящего инспекцию" &&
                  question.questName !== "Кто провел"
                ) {
                  checklist.section_start.questions.push(question);
                } else {
                  addedq = question;
                  //console.log(addedq);
                }
              } else if (question.partition === "Заключение специалиста") {
                checklist.section_finish.questions.push(question);
              } else {
                //we skip type 6 questions
                if (question.dataTypeId !== 6) {
                  if (
                    !checklist.sections.some(section => {
                      return section.name === question.partition;
                    })
                  ) {
                    checklist.sections.push({
                      name: question.partition,
                      order: order++,
                      questions: []
                    });
                  }
                  if (question.parentId_id === 0) {
                    checklist.sections
                      .find(el => el.name === question.partition)
                      .questions.push(
                        buildQuestionTray(data.questions, question)
                      );
                  }
                }
              }
            });
            //we remove finish section for QR
            delete checklist.section_finish;
          } else {
            var questions = [];
            var subquestions = [];
            var questions_start = [
              {
                questName: "Укажите email для получения заключения",
                dataTypeId_id: 3,
                requared: true,
                answer: null,
                answers: [],
                dataFormat: null,
                fileData_id: null,
                id: 9999999999901,
                inspectionId_id: data.id,
                parentId_id: 0,
                partition: "Общая информация"
              },
              {
                questName: "Укажите имя",
                dataTypeId_id: 3,
                requared: false,
                answer: null,
                answers: [],
                dataFormat: null,
                fileData_id: null,
                id: 9999999999902,
                inspectionId_id: data.id,
                parentId_id: 0,
                partition: "Общая информация"
              },
              {
                questName: "Укажите фамилию",
                dataTypeId_id: 3,
                requared: false,
                answer: null,
                answers: [],
                dataFormat: null,
                fileData_id: null,
                id: 9999999999903,
                inspectionId_id: data.id,
                parentId_id: 0,
                partition: "Общая информация"
              }
            ];
            var questions_finish = [];

            data.questions.forEach(question => {
              if (question.parentId_id === null) {
                question.parentId_id = 0;
              }
              question.answer = null;
              if (question.partition === "Общая информация") {
                if (
                  question.questName !== "Комментарий" &&
                  question.questName !==
                    "Любой дополнительный комментарий по инспекции"
                ) {
                  question.requared = true;
                }
                if (
                  question.questName !== "Ф.И.О. проводящего инспекцию" &&
                  question.questName !== "Кто провел"
                ) {
                  questions_start.push(question);
                } else {
                  addedq = question;
                  // console.log(addedq);
                }
              } else if (question.partition === "Заключение специалиста") {
                question.requared = true;
                questions_finish.push(question);
              } else {
                //we skip type 6 questions
                if (question.dataTypeId !== 6) {
                  if (question.parentId_id === 0) {
                    questions.push(question);
                  } else {
                    subquestions.push(question);
                  }
                }
              }
            });

            checklist = {
              sections: {
                questions:
                  data.hasOwnProperty("randomize") && data.randomize
                    ? shuffle(questions).concat(questions_start)
                    : questions.concat(questions_start)
              },
              section_sub: {
                questions: subquestions
              }
            };
          }
          // console.log(checklist);
          //  console.log(addedq);
          //  console.log(data.hasOwnProperty("mainProc") ? data.mainProc : {});
          commit(SET_CHECKLIST, checklist);
          commit(SET_CHECKLIST_INFO, {
            id: data.id,
            processType: data.resolveProcess,
            processName: data.processName,
            processDescription: data.description,
            processAim: data.condition_type_id,
            processTime: 0,
            hours:
              data.time_before === null && data.time_after === null
                ? true
                : false,
            processDateStart: data.dateBegin,
            processDateFinish: data.dateEnd,
            processPeriod: null,
            timeStart: data.time_before,
            timeFinish: data.time_after,
            payment: data.payment,
            price: data.price,
            locations: [],
            roles: [],
            mainProc: data.hasOwnProperty("mainProc") ? data.mainProc : {},
            visibleType: data.visibleType,
            randomize: data.hasOwnProperty("randomize")
              ? data.randomize
              : false,
            addedq: addedq
          });
          commit(SET_CHECKLIST_LOADED, true);
          commit(SET_UUID, payload.uuid4);
          resolve(data);
        })
        .catch(response => {
          console.error(response);
        });
    });
  },

  [SAVE_CHECKLIST]({ commit, state }, payload) {
    // console.log(state);
    var checklist = state.checklist;
    // console.log(state.checklist_info.addedq);
    if (state.uuid4 !== null) {
      var author = {};
      var email_index = null;
      var name_index = null;
      var surname_index = null;
      if (state.checklist_info.visibleType) {
        // all in one - has section_start
        email_index = checklist.section_start.questions.findIndex(
          q => q.id === 9999999999901
        );
        author.email = checklist.section_start.questions[email_index].answer;
        checklist.section_start.questions.splice(email_index, 1);
        name_index = checklist.section_start.questions.findIndex(
          q => q.id === 9999999999902
        );
        author.name = checklist.section_start.questions[name_index].answer;
        checklist.section_start.questions.splice(name_index, 1);
        surname_index = checklist.section_start.questions.findIndex(
          q => q.id === 9999999999903
        );
        author.surname =
          checklist.section_start.questions[surname_index].answer;
        checklist.section_start.questions.splice(surname_index, 1);
        checklist.section_start.questions.push(state.checklist_info.addedq);
      } else {
        //no section_start, questions in sections
        email_index = checklist.sections.questions.findIndex(
          q => q.id === 9999999999901
        );
        author.email = checklist.sections.questions[email_index].answer;
        checklist.sections.questions.splice(email_index, 1);
        name_index = checklist.sections.questions.findIndex(
          q => q.id === 9999999999902
        );
        author.name = checklist.sections.questions[name_index].answer;
        checklist.sections.questions.splice(name_index, 1);
        surname_index = checklist.sections.questions.findIndex(
          q => q.id === 9999999999903
        );
        author.surname = checklist.sections.questions[surname_index].answer;
        checklist.sections.questions.splice(surname_index, 1);
        checklist.sections.questions.push(state.checklist_info.addedq);
      }
    }
    // console.log(checklist);
    var postInfo = {
      checklist:
        state.uuid4 === null
          ? { ...state.common_info, ...checklist }
          : {
              ...{ processUuid: state.uuid4 },
              ...checklist,
              ...{ author: author }
            },
      mailgroups: payload.mailgroups
    };
    if (
      state.checklist_info.processType &&
      checklist.hasOwnProperty("section_finish")
    ) {
      postInfo.targetId =
        state.checklist.section_finish.questions[0].answer[0].id;
    }
    if (
      state.checklist_info.relatesProcess_id !== null &&
      payload.status !== ""
    ) {
      postInfo.status = payload.status;
    }
    //console.log(postInfo);
    /* postInfo.checklist.section_start.questions.push(
      state.checklist_info.addedq
    ); */
    // console.log(postInfo);
    return new Promise(resolve => {
      ApiService.post("api/v1/gafm3/", postInfo)
        .then(({ data }) => {
          //   console.log(data.data.statusId);
          // mutate state of finishedId
          if (data.data.hasOwnProperty("statusId")) {
            commit(SET_FINISHED_ID, data.data.statusId);
          }
          commit(PURGE_CHECKLIST);
          resolve(data);
        })
        .catch(response => {
          console.error(response);
        });
    });
  }
};

const mutations = {
  [SET_CHECKLIST](state, checklist) {
    state.checklist = checklist;
  },
  [SET_COMMON_INFO](state, common_info) {
    state.common_info = common_info;
  },
  [SET_CHECKLIST_INFO](state, checklist_info) {
    state.checklist_info = checklist_info;
  },
  [SET_CHECKLIST_LOADED](state, isChecklistLoaded) {
    state.isChecklistLoaded = isChecklistLoaded;
  },
  [SET_QUESTION_INDEX](state, questionIndex) {
    state.questionIndex = questionIndex;
  },
  [SET_UUID](state, uuid4) {
    state.uuid4 = uuid4;
  },
  [SET_FINISHED_ID](state, finishedId) {
    state.finishedId = finishedId;
  },
  [PURGE_CHECKLIST](state) {
    state.common_info = {};
    state.checklist = {};
    state.checklist_info = {};
    state.isChecklistLoaded = false;
    state.questionIndex = 0;
    //state.uuid4 = null;
  }
};

export default {
  state,
  actions,
  mutations,
  getters
};
