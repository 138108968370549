import ApiService from "@/core/services/api.service";
// action types
export const SEND_PROCESS = "sendProcess";
export const LOAD_PROCESS = "loadProcess";
export const LOAD_TRUE_PROCESS = "loadRealProcess";
// mutation types
export const SET_CHECKLIST_STRUCTURE = "setChecklistStructure";
export const SET_PROCESS = "setProcess";
export const PURGE_PROCESS_STATE = "purgeProcessState";
export const SET_PROCESS_STATUS = "setProcessStatus";
export const SET_PROCESS_ID = "setProcessId";

function buildQuestionTray(questions, question, format = "new") {
  var ret = {};
  if (format === "old") {
    ret = {
      type: question.dataTypeId_id,
      text: question.questName,
      value: "",
      file: question.fileData_id,
      id: question.id,
      required: question.requared,
      answers: question.answers.map(answer => {
        return {
          text: answer.answerValue,
          placeholder: "",
          id: answer.id,
          subs: true,
          deletable: true,
          readonly:
            question.dataTypeId_id === 1 || question.dataTypeId_id === 10
              ? true
              : false
        };
      })
    };
    //console.log(ret);
    if (ret.answers.length < 1) {
      ret.answers = [
        {
          text: " ",
          placeholder: "Текст ответа",
          subs: false,
          deletable: true,
          readonly: false,
          order: 1,
          isFile: false,
          file: ""
        }
      ];
    }
    //console.log(ret);
  } else {
    ret = question;
  }

  if (ret.answers.length > 0) {
    ret.answers.forEach(answer => {
      // console.log(answer);
      var subqarr = questions.filter(quest => quest.parentId_id === answer.id);
      // console.log(subqarr);
      if (subqarr.length > 0) {
        answer.questions = [];
        subqarr.forEach(q => {
          answer.questions.push(buildQuestionTray(questions, q, format));
        });
      }
    });
  }
  return ret;
}

const state = {
  processId: null,
  processStatus: "create",
  process: {
    processType: "safe",
    visibleType: false,
    randomize: false,
    processName: "",
    processDescription: "",
    processAim: "",
    processTime: 0,
    hours: true,
    processDateStart: null,
    processDateFinish: null,
    processPeriod: null,
    timeStart: null,
    timeFinish: null,
    payment: false,
    price: 0,
    selectedLocations: [],
    selectedRoles: [],
    selectedMailgroups: []
  },
  checklist_structure: {
    section_start: {
      name: "Общая информация",
      questions: [
        // this is required anyway, just hide in UI
        {
          id: 1,
          type: 6,
          text: "Кто провел",
          value: "",
          file: "",
          required: false,
          answers: [],
          order: 1
        },
        {
          id: 2,
          type: 3,
          text: "Укажите email для получения заключения",
          value: "",
          file: "",
          required: true,
          answers: [],
          order: 2
        },
        {
          id: 3,
          type: 3,
          text: "Укажите имя",
          value: "",
          file: "",
          required: false,
          answers: [],
          order: 3
        },
        {
          id: 4,
          type: 3,
          text: "Укажите фамилию",
          value: "",
          file: "",
          required: false,
          answers: [],
          order: 4
        }
      ]
    },
    sections: [
      {
        name: "",
        order: 1,
        questions: [
          {
            id: 7,
            type: 0,
            text: "",
            value: "",
            file: "",
            answers: [
              {
                text: "",
                placeholder: "Текст ответа",
                subs: false,
                deletable: false,
                readonly: false,
                order: 1,
                isFile: false,
                file: ""
              }
            ],
            order: 1,
            required: true
          }
        ]
      }
    ],
    section_finish: {
      name: "Заключение специалиста",
      questions: [
        {
          id: 5,
          type: 6,
          text: "Укажите специалиста",
          value: "",
          required: false,
          answers: [],
          order: 1
        }
      ]
    }
  }
};

const getters = {
  currentProcess(state) {
    return state.process;
  },

  currentCheckList(state) {
    return state.checklist_structure;
  },

  getProcessStatus(state) {
    return state.processStatus;
  },

  getProcessId(state) {
    return state.processId;
  }
};

function clearAnswer(answer) {
  delete answer.currentFile;
  if (answer.hasOwnProperty("questions") && answer.questions.length) {
    answer.questions.forEach(question => {
      if (question.hasOwnProperty("answers") && question.answers.length) {
        question.answers.forEach(answer => {
          clearAnswer(answer);
        });
      }
    });
  }
}

const actions = {
  [SEND_PROCESS]({ commit, state }) {
    //console.log(state);

    var checklist_structure = state.checklist_structure;
    checklist_structure.section_start.questions = checklist_structure.section_start.questions.slice(
      0,
      1
    );

    checklist_structure.sections.forEach(section => {
      section.questions.forEach(question => {
        if (question.hasOwnProperty("answers") && question.answers.length) {
          question.answers.forEach(answer => {
            clearAnswer(answer);
          });
        }
      });
    });

    commit(SET_CHECKLIST_STRUCTURE, checklist_structure);

    /*console.log({
      process: state.process,
      questions: state.checklist_structure
    });*/

    if (state.processStatus !== "edit") {
      return new Promise(resolve => {
        ApiService.post("api/v1/cm2/", {
          process: state.process,
          questions: state.checklist_structure
        })
          .then(({ data }) => {
            // console.log(data);
            commit(PURGE_PROCESS_STATE);
            resolve(data);
          })
          .catch(response => {
            console.error(response);
          });
      });
    } else {
      return new Promise(resolve => {
        ApiService.patch(`api/v1/cm2/${state.processId}/`, {
          process: state.process,
          questions: state.checklist_structure
        })
          .then(({ data }) => {
            //  console.log(data);
            commit(PURGE_PROCESS_STATE);
            resolve(data);
          })
          .catch(({ response }) => {
            console.error(response);
          });
      });
    }
  },

  [LOAD_PROCESS]({ commit }, payload) {
    return new Promise(resolve => {
      ApiService.post("api/v1/rp2/", payload).then(response => {
        //  console.log(response);
        var checklist;
        if (response.data.hasOwnProperty("process")) {
          //    console.log(response.data.questions);
          checklist = response.data.questions;
          // for psy
          delete checklist.section_start;
          checklist.section_start = {
            name: "Общая информация",
            questions: [
              {
                id: 1,
                type: 6,
                text: "Кто провел",
                value: "",
                file: "",
                required: false,
                answers: [],
                order: 1
              },
              {
                id: 2,
                type: 3,
                text: "Укажите email для получения заключения",
                value: "",
                file: "",
                required: true,
                answers: [
                  {
                    text: "Укажите email",
                    isFile: false,
                    file: ""
                  }
                ],
                order: 2
              },
              {
                id: 3,
                type: 3,
                text: "Укажите имя",
                value: "",
                file: "",
                required: false,
                answers: [
                  {
                    text: "Укажите имя",
                    isFile: false,
                    file: ""
                  }
                ],
                order: 3
              },
              {
                id: 4,
                type: 3,
                text: "Укажите фамилию",
                value: "",
                file: "",
                required: false,
                answers: [
                  {
                    text: "Укажите фамилию",
                    isFile: false,
                    file: ""
                  }
                ],
                order: 4
              }
            ]
          };
          // console.log(checklist);
          commit(SET_CHECKLIST_STRUCTURE, checklist);
          commit(SET_PROCESS, response.data.process);
        } else {
          var order = 1;
          checklist = {
            section_start: {
              name: "Общая информация",
              questions: []
            },
            sections: [],
            section_finish: {
              name: "Заключение специалиста",
              questions: []
            }
          };
          response.data.questions.forEach(question => {
            if (question.parentId_id === null) {
              question.parentId_id = 0;
            }
            question.answer = null;
            if (
              question.partition === "Общая информация" ||
              question.partition === "Основная информация"
            ) {
              checklist.section_start.questions.push(question);
            } else if (question.partition === "Заключение специалиста") {
              checklist.section_finish.questions.push(question);
            } else {
              if (
                !checklist.sections.some(section => {
                  return section.name === question.partition;
                })
              ) {
                checklist.sections.push({
                  name: question.partition,
                  order: order++,
                  questions: []
                });
              }
              if (question.parentId_id === 0) {
                checklist.sections
                  .find(el => el.name === question.partition)
                  .questions.push(
                    buildQuestionTray(response.data.questions, question, "old")
                  );
              }
            }
          });

          checklist.section_start.questions.forEach(q => {
            if (q.questName !== "Комментарий") {
              q.requared = true;
            }
          });
          if (response.data.resolveProcess) {
            checklist.section_finish.questions.forEach(q => {
              q.requared = true;
            });
          }

          var process = {
            processType: response.data.resolveProcess ? "unsafe" : "safe",
            visibleType: response.data.visibleType,
            randomize: response.data.hasOwnProperty("randomize")
              ? response.data.randomize
              : false,
            processName: response.data.processName,
            processDescription: response.data.description,
            processAim: {
              id: response.data.condition_type_id
            },
            processTime: response.data.repeat === "True" ? 2 : 1,
            hours: !response.data.time_before ? true : false,
            processDateStart: response.data.dateBegin,
            processDateFinish: response.data.dateEnd,
            processPeriod: null,
            timeStart: response.data.time_before,
            timeFinish: response.data.time_after,
            selectedLocations: response.data.locations,
            selectedRoles: response.data.roles,
            selectedMailgroups: []
          };
          // for psy
          checklist.section_start = {
            name: "Общая информация",
            questions: [
              {
                id: 1,
                type: 6,
                text: "Кто провел",
                value: "",
                file: "",
                required: false,
                answers: [],
                order: 1
              },
              {
                id: 2,
                type: 3,
                text: "Укажите email для получения заключения",
                value: "",
                file: "",
                required: true,
                answers: [
                  {
                    text: "Укажите email",
                    isFile: false,
                    file: ""
                  }
                ],
                order: 2
              },
              {
                id: 3,
                type: 3,
                text: "Укажите имя",
                value: "",
                file: "",
                required: false,
                answers: [
                  {
                    text: "Укажите имя",
                    isFile: false,
                    file: ""
                  }
                ],
                order: 3
              },
              {
                id: 4,
                type: 3,
                text: "Укажите фамилию",
                value: "",
                file: "",
                required: false,
                answers: [
                  {
                    text: "Укажите фамилию",
                    isFile: false,
                    file: ""
                  }
                ],
                order: 4
              }
            ]
          };
          //   console.log(checklist);
          commit(SET_CHECKLIST_STRUCTURE, checklist);
          commit(SET_PROCESS, process);
        }
        resolve(response);
      });
    }).catch(({ response }) => {
      console.error(response);
    });
  },

  [LOAD_TRUE_PROCESS]({ commit }, payload) {
    return new Promise(resolve => {
      ApiService.post("api/v1/rp/", payload).then(response => {
        //  console.log(response);
        var order = 1;
        var checklist = {
          section_start: {
            name: "Общая информация",
            questions: []
          },
          sections: [],
          section_finish: {
            name: "Заключение специалиста",
            questions: []
          }
        };
        response.data.questions.forEach(question => {
          if (question.parentId_id === null) {
            question.parentId_id = 0;
          }
          question.answer = null;
          if (question.partition === "Общая информация") {
            checklist.section_start.questions.push(question);
          } else if (question.partition === "Заключение специалиста") {
            checklist.section_finish.questions.push(question);
          } else {
            if (
              !checklist.sections.some(section => {
                return section.name === question.partition;
              })
            ) {
              checklist.sections.push({
                name: question.partition,
                order: order++,
                questions: []
              });
            }
            if (question.parentId_id === 0) {
              checklist.sections
                .find(el => el.name === question.partition)
                .questions.push(
                  buildQuestionTray(response.data.questions, question)
                );
            }
          }
        });

        checklist.section_start.questions.forEach(q => {
          if (q.questName !== "Комментарий") {
            q.requared = true;
          }
        });
        if (response.data.CommentInspection.process.processType === "unsafe") {
          checklist.section_finish.questions.forEach(q => {
            q.requared = true;
          });
        }

        // for psy
        checklist.section_start = {
          name: "Общая информация",
          questions: [
            {
              id: 1,
              type: 6,
              text: "Кто провел",
              value: "",
              file: "",
              required: false,
              answers: [],
              order: 1
            },
            {
              id: 2,
              type: 3,
              text: "Укажите email для получения заключения",
              value: "",
              file: "",
              required: true,
              answers: [
                {
                  text: "Укажите email",
                  isFile: false,
                  file: ""
                }
              ],
              order: 2
            },
            {
              id: 3,
              type: 3,
              text: "Укажите имя",
              value: "",
              file: "",
              required: false,
              answers: [
                {
                  text: "Укажите имя",
                  isFile: false,
                  file: ""
                }
              ],
              order: 3
            },
            {
              id: 4,
              type: 3,
              text: "Укажите фамилию",
              value: "",
              file: "",
              required: false,
              answers: [
                {
                  text: "Укажите фамилию",
                  isFile: false,
                  file: ""
                }
              ],
              order: 4
            }
          ]
        };

        // console.log(checklist);

        commit(SET_CHECKLIST_STRUCTURE, checklist);
        commit(SET_PROCESS, response.data.CommentInspection.process);
        commit(SET_PROCESS_ID, response.data.id);

        resolve(response);
      });
    }).catch(({ response }) => {
      console.error(response);
    });
  }
};

const mutations = {
  [SET_CHECKLIST_STRUCTURE](state, checklist_structure) {
    state.checklist_structure = checklist_structure;
  },
  [SET_PROCESS](state, process) {
    state.process = process;
  },
  [SET_PROCESS_ID](state, processId) {
    state.processId = processId;
  },
  [SET_PROCESS_STATUS](state, processStatus) {
    state.processStatus = processStatus;
  },
  [PURGE_PROCESS_STATE](state) {
    state.processId = null;
    state.processStatus = "create";
    state.checklist_structure = {
      section_start: {
        name: "Общая информация",
        questions: [
          {
            id: 1,
            type: 6,
            text: "Кто провел",
            value: "",
            file: "",
            required: false,
            answers: [],
            order: 1
          },
          {
            id: 2,
            type: 3,
            text: "Укажите email для получения заключения",
            value: "",
            file: "",
            required: true,
            answers: [
              {
                text: "Укажите email",
                isFile: false,
                file: ""
              }
            ],
            order: 2
          },
          {
            id: 3,
            type: 3,
            text: "Укажите имя",
            value: "",
            file: "",
            required: false,
            answers: [
              {
                text: "Укажите имя",
                isFile: false,
                file: ""
              }
            ],
            order: 3
          },
          {
            id: 4,
            type: 3,
            text: "Укажите фамилию",
            value: "",
            file: "",
            required: false,
            answers: [
              {
                text: "Укажите фамилию",
                isFile: false,
                file: ""
              }
            ],
            order: 4
          }
        ]
      },
      sections: [
        {
          name: "",
          order: 7,
          questions: [
            {
              id: 7,
              type: 0,
              text: "",
              file: "",
              value: "",
              answers: [],
              order: 1,
              required: true
            }
          ]
        }
      ],
      section_finish: {
        name: "Заключение специалиста",
        questions: [
          {
            id: 5,
            type: 6,
            text: "Укажите специалиста",
            value: "",
            required: false,
            answers: [],
            order: 1
          }
        ]
      }
    };
    state.process = {
      processType: "safe",
      visibleType: false,
      randomize: false,
      processName: "",
      processDescription: "",
      processAim: "",
      processTime: 0,
      hours: true,
      processDateStart: null,
      processDateFinish: null,
      processPeriod: null,
      payment: false,
      price: 0,
      timeStart: null,
      timeFinish: null,
      selectedLocations: [],
      selectedRoles: [],
      selectedMailgroups: []
    };
    // console.log(state.checklist_structure.section_start);
  }
};

export default {
  state,
  actions,
  mutations,
  getters
};
