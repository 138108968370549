import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "@/core/services/store";
import ApiService from "@/core/services/api.service";
// import MockService from "@/core/mock/mock.service";
import { VERIFY_AUTH } from "@/core/services/store/auth.module";
import { RESET_LAYOUT_CONFIG } from "@/core/services/store/config.module";

Vue.config.productionTip = false;

// Global 3rd party plugins
import "popper.js";
import "tooltip.js";
import PerfectScrollbar from "perfect-scrollbar";
window.PerfectScrollbar = PerfectScrollbar;
import ClipboardJS from "clipboard";
window.ClipboardJS = ClipboardJS;

// Vue 3rd party plugins
import i18n from "@/core/plugins/vue-i18n";
import vuetify from "@/core/plugins/vuetify";
import "@/core/plugins/portal-vue";
import "@/core/plugins/bootstrap-vue";
import "@/core/plugins/perfect-scrollbar";
import "@/core/plugins/highlight-js";
import "@/core/plugins/inline-svg";
import "@/core/plugins/apexcharts";
import "@/core/plugins/treeselect";
import "@/core/plugins/metronic";
import "@mdi/font/css/materialdesignicons.css";
import "@/core/plugins/formvalidation";

// API service init
ApiService.init();

Vue.prototype.$host = "https://dev.chekr.ru";
// Remove this to disable mock API
//MockService.init();

router.beforeEach((to, from, next) => {
  // Ensure we checked auth before each page load.
  //console.log(to.query.id);
  //console.log(store.getters.getUuid);
  if (to.path !== "/login") {
    if (typeof to.query.id === "undefined" && store.getters.getUuid === null) {
      //console.log('verify');
      Promise.all([store.dispatch(VERIFY_AUTH)]).then(
        () => {
          //console.log(to.path);
          //console.log(store.getters.currentUser);
          //console.log(store.getters.isAuthenticated);
          if (
            store.getters.isAuthenticated &&
            store.getters.currentUser.hasOwnProperty("user")
          ) {
            if (
              !store.getters.currentUser.user.groups.includes(1) &&
              to.path !== "/checklist/all" &&
              to.path !== "/checklist/operate" &&
              to.path !== "/mailgroups" &&
              to.path !== "/account/profile"
            ) {
              //console.log("should redirect to checklist");
              next({ name: "checklist" });
            } else {
              //console.log("load current");
              next();
            }
          } else {
            next({ name: "login" });
          }
        },
        reason => {
          console.error(reason);
        }
      );
    } else {
      //console.log('skip verify');
      if (to.path === "/qr" || to.path === "/paymentsuccess") {
        next();
      } else {
        next({ name: "login" });
      }
    }
  } else {
    next();
  }

  // reset config to initial state
  store.dispatch(RESET_LAYOUT_CONFIG);

  // Scroll page to top on every route change
  setTimeout(() => {
    window.scrollTo(0, 0);
  }, 100);
});

new Vue({
  router,
  store,
  i18n,
  vuetify,
  render: h => h(App)
}).$mount("#app");
