import ApiService from "@/core/services/api.service";
import JwtService from "@/core/services/jwt.service";

//import profile from "./profile.module";
import {
  SET_PERSONAL_INFO,
  SET_ACCOUNT_INFO
} from "@/core/services/store/profile.module";

import { PURGE_PROCESS_STATE } from "@/core/services/store/process.module";

// action types
export const VERIFY_AUTH = "verifyAuth";
export const LOGIN = "login";
export const LOGOUT = "logout";
export const REGISTER = "register";
export const UPDATE_PASSWORD = "updateUser";

// mutation types
export const PURGE_AUTH = "logOut";
export const SET_AUTH = "setUser";
export const SET_PASSWORD = "setPassword";
export const SET_ERROR = "setError";

const state = {
  errors: {},
  user: {},
  isAuthenticated: !!JwtService.getToken()
};

const getters = {
  currentUser(state) {
    return state.user;
  },
  isAuthenticated(state) {
    return state.isAuthenticated;
  }
};

const actions = {
  [LOGIN](context, credentials) {
    return new Promise(resolve => {
      ApiService.post("api/v1/auth/login/", credentials)
        .then(({ data }) => {
          context.commit(SET_AUTH, data);
          resolve(data);
        })
        .catch(({ response }) => {
          if (typeof response !== "undefined") {
            context.commit(SET_ERROR, {
              message: response.data.non_field_errors[0]
            });
          } else {
            context.commit(SET_ERROR, {
              message:
                "Произошла ошибка! Убедитесь в правильности введенных данных!"
            });
          }
        });
    });
  },
  [LOGOUT](context) {
    context.commit(PURGE_AUTH);
  },
  [REGISTER](context, credentials) {
    return new Promise(resolve => {
      ApiService.post("api/v1/createUser/", credentials)
        .then(({ data }) => {
          context.commit(SET_AUTH, data);
          resolve(data);
        })
        .catch(({ response }) => {
          console.error(response);
          context.commit(SET_ERROR, {
            messageNew:
              "Ошибка при создании пользователя! Такой e-mail уже зарегистрирован в системе!"
          });
        });
    });
  },
  [VERIFY_AUTH](context) {
    // console.log(JwtService.getToken());
    if (JwtService.getToken() && typeof JwtService.getToken() !== "undefined") {
      ApiService.setHeader();
      return new Promise(resolve => {
        ApiService.post("api/v1/auth/verify/", { token: JwtService.getToken() })
          .then(({ data }) => {
            // console.log(data);
            context.commit(SET_AUTH, data);
            resolve();
          })
          .catch(response => {
            // console.log(response);
            if (
              response.hasOwnProperty("data") &&
              response.data.hasOwnProperty("errors")
            ) {
              context.commit(SET_ERROR, response.data.errors);
            } else {
              context.commit(SET_ERROR, "Ошибка при запросе на сервер!");
            }
            context.commit(PURGE_AUTH);
          });
      });
    } else {
      context.commit(PURGE_AUTH);
    }
  },
  [UPDATE_PASSWORD](context, credentials) {
    return new Promise(resolve => {
      ApiService.post("api/v1/updatePassword/", credentials)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          context.commit(SET_ERROR, { messageRec: response.data });
        });
    });
  }
};

const mutations = {
  [SET_ERROR](state, error) {
    state.errors = error;
  },
  [SET_AUTH](state, user) {
    state.isAuthenticated = true;
    state.user = user;

    // update profile
    let photo = "media/users/300_21.jpg";
    let name = user.user.first_name;
    let surname = user.user.last_name;
    let company_name = user.org.name;
    let job = "Application Developer";
    let email = user.user.username;
    let phone = "44(76)34254578";
    let company_site = "fifestudios";

    let username = user.user.username;
    let language = "ru";
    let time_zone = "(GMT+3:00) Moscow";
    let communication = {
      email: true,
      sms: false,
      phone: false
    };
    let verification = true;

    this.commit(SET_PERSONAL_INFO, {
      photo: photo,
      name: name,
      surname: surname,
      company_name: company_name,
      job: job,
      email: email,
      phone: phone,
      company_site: company_site
    });

    this.commit(SET_ACCOUNT_INFO, {
      username: username,
      email: email,
      language: language,
      time_zone: time_zone,
      communication: communication,
      verification: verification
    });

    state.errors = {};
    //save token
    JwtService.saveToken(user.token);
  },

  [SET_PASSWORD](state, password) {
    state.user.password = password;
  },

  [PURGE_AUTH](state) {
    state.isAuthenticated = false;
    state.user = {};
    state.errors = {};
    JwtService.destroyToken();
    this.commit(PURGE_PROCESS_STATE);
    //console.log(this);
    //this.replaceState({});
    //window.location.reload
  }
};

export default {
  state,
  actions,
  mutations,
  getters
};
